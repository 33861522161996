<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else-if="ratings.length" class="my-3">
      <h4 class="text-center my-3">Оценки НИШ и колледж</h4>

      <div class="card my-3">
        <div class="card-body">
          <div class="card-title mb-4">
            <h5>Информация о студенте</h5>
          </div>
          <div>
            <p>
              <b>ФИО:</b> {{ studentInfos?.last_name }} {{ studentInfos?.first_name }} {{studentInfos?.middle_name }}
            </p>
            <p><b>Баркод:</b> {{ studentInfos?.barcode }}</p>
            <p>
              <b>ОП:</b> {{studentInfos?.educationProgram?.education_speciality_code}}
              {{studentInfos?.educationProgram?.education_speciality_name}}
            </p>
            <p><b>Уровень обучения:</b> {{ studentInfos?.studyLevel?.name }}</p>
            <p><b>Форма обучения:</b> {{ studentInfos?.studyForm?.name }}</p>
            <p><b>Курс:</b> {{ studentInfos?.study_course }}</p>
            <p><b>Группа:</b> {{ studentInfos?.studentGroups?.name }}</p>
          </div>
        </div>
      </div>


      <DataTable :value="ratings" stripedRows>
        <Column field="discipline_name" header="Дисциплина"></Column>
        <Column field="semester" header="Семестр"></Column>
        <Column field="credit" header="Кредит"></Column>
        <Column field="vsk1" header="ВСК1"></Column>
        <Column field="vsk2" header="ВСК2"></Column>
        <Column field="exam" header="Экзамен"></Column>
        <Column field="total" header="Общий балл">
          <template #body="{data}">
            <Button :label="`${data.total||'Нет оценки'}`" class="m-1" @click="openChangeTotalGrade(data.id)"/>
          </template>
        </Column>
      </DataTable>
    </div>
    <div v-else>
      <h5 class="text-center mt-4">
        Здесь пусто
      </h5>
    </div>


    <Dialog header="Общий балл" v-model:visible="displayChangeTotalGrade" :style="{width: '100%', maxWidth: '400px'}"
            :modal="true" :closable="false">
      <div class="px-1">
        <div class="row mt-4">
          <div class="col-md-6">
            <p>Новый общий балл</p>
          </div>
          <div class="col-md-6">
            <input class="form-control" id="number" type="number" max="100" min="0" placeholder="Общий балл"
                   v-model="total">
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeChangeTotalGrade"/>
        <Button :label="btnPutTotalGradeStatus?'Сохранение':'Поставить оценку'" icon="pi pi-check" autofocus
                :disabled="btnPutTotalGradeStatus" @click="putTotalGrade"/>
      </template>
    </Dialog>

  </div>
</template>

<script>
  import httpClient from "@/services/http.service"

  export default {
    name: "NisCollegeRatings",
    data() {
      return {
        loading: true,
        studentId: +this.$route.params.id || 0,
        ratings: [],
        displayChangeTotalGrade: false,
        ratingId: 0,
        total: 0,
        btnPutTotalGradeStatus: false
      }
    },
    methods: {
      async getStudentDataById(studentId) {
        try {
          const {status, data} = await httpClient.get(`student_data/student-data/get-info-by-barcode?student_id=${studentId}`)
          if (status === 200) {
            this.studentInfos = data.success
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
      },
      async getRatings(studentId) {
        try {
          const {status, data} = await httpClient.get(`old_ratings/old-student-ratings/get-student-ratings?student_id=${studentId}`)
          if (status === 200) {
            this.ratings = data
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
      },
      async putTotalGrade() {
        this.btnPutTotalGradeStatus = true
        try {
          const {status} = await httpClient.post(`old_ratings/old-student-ratings/update-ratings?id=${this.ratingId}`, {total: this.total})
          if (status === 200) {
            await this.getRatings(this.studentId)
            this.closeChangeTotalGrade()
            this.$message({text: `Оценка успешно поставлена`})
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
        this.btnPutTotalGradeStatus = false
      },

      openChangeTotalGrade(ratingId) {
        this.ratingId = ratingId
        this.total = this.ratings.find(r => r.id == ratingId)?.total
        this.displayChangeTotalGrade = true
      },
      closeChangeTotalGrade() {
        this.ratingId = 0
        this.displayChangeTotalGrade = false
      },

    },
    async mounted() {
      if (this.studentId) {
        await this.getStudentDataById(this.studentId)
        await this.getRatings(this.studentId)
      }
      this.loading = false
    }
  }
</script>

<style scoped>

</style>